import React from 'react';
import styled, { css } from 'styled-components';

import Logo from '../svg/logo.svg';

const Image = styled.div`
  position: relative;
  min-width: 100%;
  min-height: 100vh;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(${Logo});
    background-repeat: no-repeat;

    background-size: cover;
    background-attachment: fixed;
    ${props =>
      css`
        background-position: center ${props.top};
      `}
  }
`;

const Container = styled.div`
  position: fixed;
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(90deg, #eee, #f7f7f7);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: -1;
`;

const Background = () => {
  //const top = useParallax();
  const top = 0;

  return (
    <Container>
      <Image top={top} />
    </Container>
  );
};

export default Background;
