import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  width: 80%;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px 0;
  overflow: visible;
`;

const Card = ({ children }) => {
  return <Container>{children}</Container>;
};

export default Card;
