import React from 'react';
import Reset from './reset';
import Head from './head';
import Background from './background';

const Layout = ({ children }) => {
  return (
    <>
      <Reset />
      <Head />
      <Background />
      {children}
    </>
  );
};

export default Layout;
