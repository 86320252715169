import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const BASE_SIZE = 10;

const Ghost = styled.div`
  position: absolute;
  visibility: hidden;
  font-size: ${BASE_SIZE}px;
  font-weight: 800;
  text-transform: uppercase;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FitRow = ({ words, width, index, render }) => {
  const [fontSize, setFontSize] = useState(0);
  const ghost = useRef();

  useEffect(() => {
    const baseWidth = ghost?.current.clientWidth;
    const factor = width / baseWidth;
    setFontSize(+(BASE_SIZE * factor).toFixed(2));
  }, [words, width]);

  return (
    <>
      <Row index={index}>
        <Ghost ref={ghost} aria-hidden="true">
          {words}
        </Ghost>
        {render(fontSize)}
      </Row>
    </>
  );
};

export default FitRow;
