import React from 'react';
import styled from 'styled-components';

import Logo from '../svg/logo.svg';
import Layout from '../components/layout';
import Card from '../components/card';
import List from '../components/list';
import Contact from '../components/contact';
import Projects from '../components/projects';

const Main = styled.main`
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 40px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 900px;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 800;
  z-index: 1;
  text-align: right;
`;

const Image = styled.div`
  width: 40px;
  min-height: 28px;
  margin-left: 10px;
  background-image: url(${Logo});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  filter: brightness(0);
`;

const Subtitle = styled.h2`
  width: 80%;
  max-width: 900px;
  margin: 0 auto;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  color: #888;
`;

const Body = styled.div`
  flex: 1;
  width: 80%;
  max-width: 900px;
  margin: 0 auto;
  padding: 10px 0;
  font-family: Lato;
  color: #888;
`;

const Footer = styled.footer`
  padding: 20px 10%;
  background: #444;
  text-align: center;
  color: white;
`;

export default function Home() {
  return (
    <Layout>
      <Main>
        <Header>
          <Title>WASI</Title>
          <Image />
        </Header>
        <Subtitle>Custom Websites & Applications</Subtitle>
        <Card>
          <List />
        </Card>
        <Body>
          Modern development for small businesses and personal platforms.
          Uniquely yours, designed and built to connect to your audience.
        </Body>
        <Projects />
        <Contact />
        <Footer>contact@wasi.works</Footer>
      </Main>
    </Layout>
  );
}
