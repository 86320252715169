import React from 'react';
import { Helmet } from 'react-helmet';

const TITLE = 'Home';
const DESCRIPTION = 'Custom Website & Application Development';

const Head = ({ title = TITLE, description = DESCRIPTION, meta = [] }) => {
  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title={title}
      titleTemplate={`Wasi | %s`}
      meta={[
        {
          name: `description`,
          content: description
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: description
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:creator`,
          content: `Wasi`
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `twitter:description`,
          content: description
        }
      ].concat(meta)}
    />
  );
};

export default Head;
