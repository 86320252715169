import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

import Request from '../svg/request.svg';

const pulse = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.3);
  }
`;

const Icon = styled.div`
  position: relative;
  min-width: 62px;
  min-height: 62px;
  margin-right: 20px;
  margin-left: -20px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    min-height: 62px;
    min-width: 62px;
    background: url(${Request});
    background-repeat: no-repeat;
    background-size: contain;
    animation: ${pulse} 2s alternate infinite;
  }

  @media (max-width: 390px) {
    margin: 0;
    margin-bottom: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  max-width: 490px;
  margin: 40px auto;
  padding: 20px;
  padding-left: 10px;
  color: #444;
  border-radius: 8px;
  background: white;
  border: 1px solid #ddd;

  @media (max-width: 390px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Input = styled.input`
  display: block;
  width: 100%;
  margin-right: 10px;
  padding: 10px;
  font-size: 18px;
  border-radius: 4px;
  border: 1px solid #ddd;

  &:focus {
    outline: 0;
    border-color: #57cfc5;
  }

  @media (max-width: 520px) {
    margin: 0;
    margin-bottom: 10px;
  }
`;

const Button = styled.button`
  float: right;
  padding: 10px 20px;
  border: 0;
  font-size: 18px;
  cursor: pointer;
  background: #57cfc5;
  border-radius: 4px;
  color: white;

  &:hover {
    background: #52c2b9;
  }

  &:disabled {
    background: #ddd;
  }
`;

const Form = styled.form`
  display: flex;
  align-items: stretch;
  margin: 10px 0;

  @media (max-width: 520px) {
    flex-direction: column;
  }
`;

const Body = styled.div`
  font-family: Lato;
`;

const Note = styled.div`
  font-size: 12px;
  color: #888;
`;

const Contact = () => {
  const [value, setValue] = useState('');

  return (
    <Container>
      <Icon />
      <Body>
        Request a free consultation!
        <Form
          netlify-honeypot="bot-field"
          data-netlify="true"
          method="POST"
          name="contact"
          disabled={!value}
        >
          {/* Netlify Forms Fields */}
          <input type="hidden" name="bot-field" aria-hidden="true" />
          <input
            type="hidden"
            name="form-name"
            aria-hidden="true"
            value="contact"
          />

          <label htmlFor="email" hidden>
            Email
          </label>
          <Input
            id="email"
            name="email"
            placeholder="Your Email Address"
            type="email"
            onInput={e => setValue(e.target.value)}
          />
          <Button disabled={!value}>Request</Button>
        </Form>
        <Note>
          We will not share your email with anyone, ever. This is only used to
          get in touch with you about our services.
        </Note>
      </Body>
    </Container>
  );
};

export default Contact;
