import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';

const document = global.document || {};

export default function useResize(element = document.body) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const handleResize = throttle(() => {
      if (element) {
        setWidth(element.clientWidth);
      }
    }, 100);

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [element]);

  return width;
}
